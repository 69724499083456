<script>
    import Layout from "../../../layouts/main";
    import PageHeader from "@/components/page-header";
    import appConfig from "@/app.config";
    import Aws from "@/services/Aws";
    import TabTargetGroupInstances from "@/components/tabs/monitoring/tabTargetGroupInstances";

    /**
     * Application Details component
     */
    export default {
        components: {
            Layout,
            PageHeader,
            TabTargetGroupInstances,
        },
        page: {
            title: "AWS Application",
            meta: [
                {
                    name: "description",
                    content: appConfig.description,
                },
            ],
        },
        data() {
            return {
                title: "AWS Application",
                items: [
                    {
                        text: "AWS Applications",
                    },
                    {
                        text: "Application Details",
                        active: true,
                    },
                ],
                applicationData: [],
                DBClusterIdentifier: '',
                DBinstances: [],
                TargetGroupArn: '',
                LoadBalancerArn: '',
                loadBalancerData: [],
                rds_cluster: '',
                error: null,
                modalData: {},
                toggleProfile: false,
                showLoader: false,
                loadBalancerDataSpinner: false,
                DBinstancesSpinner: false
            };
        },
        async mounted() {
            this.getApplications()
        },
        methods: {

            getApplications() {

                this.showLoader = true
                this.TargetGroupArn = ''

                Aws.getApplications({
                    application_id: this.$route.params.id
                }).then((response) => {
                    if(response.data.data){
                        this.applicationData = response.data.data[0];

                        if(this.applicationData.resources){
                            this.applicationData.resources.forEach((value) => {
                                if(value.resource_type == 'targetgroups'){
                                    this.TargetGroupArn = value.resource_id
                                }

                                if(value.resource_type == 'loadbalancers'){
                                    this.getLoadBalancers(value.resource_id)
                                }

                                if(value.resource_type == 'rds_cluster'){
                                    this.getRDSInstances(value.resource_id)
                                    this.DBClusterIdentifier = value.resource_id
                                }
                            })
                        }
                    }
                }).catch((error) => {
                this.error = error.response.data.error
                    ? error.response.data.error
                    : "";
                }).finally(() => {
                    this.showLoader = false;
                });
            },

            async getLoadBalancers(LoadBalancerArn) {

                try {
                    this.loadBalancerDataSpinner = true
                    const response = await Aws.getLoadBalancers({
                        LoadBalancerArn: LoadBalancerArn
                    });
                    if(response.data.data){
                        this.loadBalancerData = response.data.data[0]
                    }
                } catch (error) {
                    this.error = error.response.data.error ? error.response.data.error : "";

                } finally {
                    this.loadBalancerDataSpinner = false;
                }
            },

            getRDSInstances(DBClusterIdentifier) {
                this.DBinstancesSpinner = true
                Aws.getRDSInstances({
                    DBClusterIdentifier: DBClusterIdentifier
                })
                    .then((response) => {
                        if (response.data) {
                            let DBinstances = response.data.data;
                            this.DBinstances = DBinstances
                        }
                    })
                    .catch((error) => {
                        this.error = error.response && error.response.data && error.response.data.error
                            ? error.response.data.error
                            : "An error occurred";
                    }).finally(() => {
                        this.DBinstancesSpinner = false
                    });
            },

            toggle() {
                this.toggleProfile = !this.toggleProfile;
            },

        },
    };
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />

        <div class="row mb-4" v-if="!this.error">
            <div v-if="!toggleProfile" class="col-xl-4">
                <div class="card h-100">
                    <div class="card-body" v-if="!showLoader">
                        <div class="text-center">
                            <b-dropdown
                                    class="float-end"
                                    variant="white"
                                    right
                                    menu-class="dropdown-menu-end"
                                    toggle-class="font-size-16 text-body p-0"
                            >
                                <template v-slot:button-content>
                                    <i class="uil uil-ellipsis-v"></i>
                                </template>
                            </b-dropdown>
                            <i class="uil uil-angle-left float-start font-size-20 text-body btn p-0" @click="toggle"></i>
                            <i class="uil uil-refresh font-size-20 btn p-0 link-primary" @click="getApplications()" title="Refresh Data"></i>
                            <div class="clearfix"></div>

                            <h3 class="mt-3 mb-1" >
                                {{applicationData.name}}
                            </h3>
                            <small class="mt-3 mb-1"></small>
                            <p class="mt-2 mb-1"></p>
                            <div class="mt-2">
                                <div class="font-size-18">

                                </div>
                            </div>
                        </div>

                        <hr class="my-2" />

                        <div class="table-responsive mt-4 mb-0">
                            <h5>Application Information</h5>
                           <!-- <div class="mt-3" v-for="(resource, index) in applicationData.resources" :key="index">
                                <p class="mb-2 text-strong" >{{resource.resource_type}} :</p>
                                <p class="font-size-16 mb-2 justify-content-between">
                                    <span class="mw-80" >
                                        {{resource.resource_id}}
                                    </span>
                                </p>
                            </div>-->

                            <hr />

                            <div class="mt-3">
                                <p class="mb-2 text-strong">Notes :</p>
                                <p class="font-size-16 mb-2 justify-content-between">
                                    <span class="mw-80">{{applicationData.note}}</span>
                                </p>
                            </div>

                        </div>
                    </div>
                    <div class="card-body d-flex align-items-center justify-content-center" v-else >
                        <b-spinner large></b-spinner>
                    </div>
                </div>
            </div>
            <div v-else class="col-xl-2">
                <div class="card h-100">
                    <div class="card-body">
                        <div class="text-center">
                            <b-dropdown
                                    class="float-end"
                                    variant="white"
                                    right
                                    menu-class="dropdown-menu-end"
                                    toggle-class="font-size-16 text-body p-0"
                            >
                                <template v-slot:button-content>
                                    <i class="uil uil-ellipsis-v"></i>
                                </template>

                            </b-dropdown>
                            <i class="uil uil-angle-right float-start font-size-20 text-body btn p-0" @click="toggle"></i>
                            <i class="uil uil-refresh font-size-20 btn p-0 link-primary" @click="getApplications()" title="Refresh Data"></i>
                            <div class="clearfix"></div>

                            <h5 class="mt-3 mb-1">
                                {{applicationData.name}}
                            </h5>
                            <small class="mt-3 mb-1"></small>

                            <div class="mt-4">
                                <div class="font-size-18"></div>
                            </div>
                        </div>
                        <hr class="my-2" />

                        <div class="table-responsive mt-4 mb-0">

                        </div>
                    </div>
                </div>
            </div>

            <div :class="!toggleProfile ? 'col-xl-8' : 'col-xl-10'">
                <div class="card mb-0">
                    <b-tabs content-class="p-4" justified class="nav-tabs-custom">
                        <b-tab active>
                            <template v-slot:title>
                                <i class="uil uil-list-ui-alt font-size-20"></i>
                                <span class="d-none d-sm-block">Target Group Instances</span>
                            </template>
                            <template v-if="TargetGroupArn">
                                <div class="row">
                                    <div class="col-sm-12 col-md-12">
                                        <div class="mb-2">
                                            <p class="mb-1 text-strong">Target Group :</p>
                                            <p class="font-size-16">{{TargetGroupArn}}</p>
                                        </div>
                                    </div>
                                </div>
                                <TabTargetGroupInstances ref="targetGroupInstances" :TargetGroupArn="TargetGroupArn"/>
                            </template>
                            <template v-else>
                                <div class="card-body d-flex align-items-center justify-content-center">
                                    <b-spinner large></b-spinner>
                                </div>
                            </template>
                        </b-tab>

                        <b-tab >
                            <template v-slot:title>
                                <i class="uil uil-list-ui-alt font-size-20"></i>
                                <span class="d-none d-sm-block">Load Balancers</span>
                            </template>
                            <template v-if="loadBalancerData && !loadBalancerDataSpinner">
                                <div class="row">

                                    <div class="col-sm-12 col-md-6">
                                        <div class="mb-2">
                                            <p class="mb-1 text-strong">LoadBalancerName :</p>
                                            <p class="font-size-16">{{loadBalancerData.LoadBalancerName}}</p>
                                        </div>
                                    </div>

                                    <div class="col-sm-12 col-md-6">
                                        <div class="mb-2">
                                            <p class="mb-1 text-strong">State :</p>
                                            <p class="font-size-16"><span class="font-size-16" v-html="format_status(loadBalancerData.State)"></span></p>
                                        </div>
                                    </div>

                                    <div class="col-sm-12 col-md-6">
                                        <div class="mb-2">
                                            <p class="mb-1 text-strong">LoadBalancerArn :</p>
                                            <p class="font-size-16">{{loadBalancerData.LoadBalancerArn}}</p>
                                        </div>
                                    </div>

                                    <div class="col-sm-12 col-md-6">
                                        <div class="mb-2">
                                            <p class="mb-1 text-strong">DNS Name :</p>
                                            <p class="font-size-16">{{loadBalancerData.DNSName}}</p>
                                        </div>
                                    </div>

                                    <div class="col-sm-12 col-md-6">
                                        <div class="mb-2">
                                            <p class="mb-1 text-strong">Type :</p>
                                            <p class="font-size-16">{{loadBalancerData.Type}}</p>
                                        </div>
                                    </div>

                                    <div class="col-sm-12 col-md-6">
                                        <div class="mb-2">
                                            <p class="mb-1 text-strong">Note :</p>
                                            <p class="font-size-16">{{loadBalancerData.note}}</p>
                                        </div>
                                    </div>

                                </div>

                            </template>
                            <template v-else>
                                <div class="card-body d-flex align-items-center justify-content-center">
                                    <b-spinner large></b-spinner>
                                </div>
                            </template>
                        </b-tab>

                        <b-tab >
                            <template v-slot:title>
                                <i class="uil uil-list-ui-alt font-size-20"></i>
                                <span class="d-none d-sm-block">RDS Cluster Instances</span>
                            </template>
                            <template v-if="DBinstances && !DBinstancesSpinner">
                                <div class="row">
                                    <div class="col-sm-12 col-md-12">
                                        <div class="mb-2">
                                            <p class="mb-1 text-strong">Cluster name :</p>
                                            <p class="font-size-16">{{DBClusterIdentifier}}</p>
                                        </div>
                                    </div>
                                </div>
                                <table class="table table-responsive">
                                    <thead>
                                    <tr>
                                        <th>DB Identifier</th>
                                        <th>DB Class</th>
                                        <th>Engine</th>
                                        <th>Instance Role</th>
                                        <th>Publicly Accessible</th>
                                        <th>Availability Zone</th>
                                        <th>DB Status</th>
                                        <th>Note</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr v-for="item in DBinstances" :key="item.DBInstanceIdentifier">
                                        <td>{{item.DBInstanceIdentifier}}</td>
                                        <td>{{item.DBInstanceClass}}</td>
                                        <td>{{item.Engine}}</td>
                                        <td>{{item.InstanceRole}}</td>
                                        <td>{{item.PubliclyAccessible}}</td>
                                        <td>{{item.AvailabilityZone}}</td>
                                        <td><div class="font-size-16" v-html="format_status(item.DBInstanceStatus)"></div></td>
                                        <td>{{item.note}}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </template>
                            <template v-else>
                                <div class="card-body d-flex align-items-center justify-content-center">
                                    <b-spinner large></b-spinner>
                                </div>
                            </template>
                        </b-tab>

                    </b-tabs>
                </div>
            </div>
        </div>
        <div v-else class="text-center mt-5">
            <div class="row">
                <div class="col-md-12">
                    <h4>{{this.error}}</h4>
                </div>
            </div>
        </div>
        <!-- end row -->

        <!-- MODALS -->

        <!-- END  MODALS -->
    </Layout>
</template>
